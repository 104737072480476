/* stylelint-disable declaration-colon-newline-after, value-list-comma-newline-after, selector-pseudo-element-no-unknown */

@font-face {
  font-display: swap;
  font-family: "VT323";
  font-style: normal;
  font-weight: 400;
  src: url(./../public/fonts/VT323-Regular.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

::target-text {
  background-color: rebeccapurple;
  color: white;
}

:root {
  color-scheme: dark;
}

::selection {
  background: hotpink;
  color: black;
}

::backdrop {
  background-color: pink;
}

::grammar-error {
  color: green;
}

::spelling-error {
  text-decoration: wavy red;
}

::marker {
  color: rgb(236, 95, 0);
  font-size: 1.2em;
}

::placeholder {
  color: red;
  font-size: 1.2em;
  font-style: italic;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior */
  scroll-behavior: smooth;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar */

/* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
body::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  background: linear-gradient(3deg, #b2f5b2, #70f);
  background-color: #b2f5b2; /* or add it to the track */
}

/* Add a thumb */
body::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #ea00ff, #ff5200);
  background-color: #ea00ff;
}

body::-webkit-scrollbar-corner {
  background: linear-gradient(-45deg, firebrick, gold);
}

html {
  position: relative;
  background-color: #282c34;
  caret-color: rgb(185, 23, 23);
  color: #a8b8d8;
  cursor: none;
  font-size: 18px;
}

html.comicsansify * {
  font-family: "Comic Sans MS", cursive, sans-serif !important;
}

html.font-size-1 {
  font-size: 1rem;
}

html.font-size-2 {
  font-size: 1.5rem;
}

html.font-size-3 {
  font-size: 2rem;
}

html.font-size-4 {
  font-size: 2.5rem;
}

html.font-size-5 {
  font-size: 3rem;
}

@media (min-width: 1200px) {
  html::after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /*
    animation: blur 180s ease 0s infinite, travel 720s ease infinite;
    background-image: url("images/IMG_0469.jpg");
    background-repeat: repeat;
    background-size: cover;
    filter: blur(0) grayscale(1);
    */
    background: radial-gradient(at 0 0, transparent, transparent),
      radial-gradient(at 40% 20%, hsl(28, 49%, 22%) 0, transparent 50%),
      radial-gradient(at 0 0, hsl(343, 52%, 22%) 0, transparent 50%),
      radial-gradient(at 0 50%, hsl(355deg 51% 28%) 0, transparent 50%),
      radial-gradient(at 0 100%, hsl(22, 51%, 23%) 0, transparent 50%),
      radial-gradient(at 80% 0, hsl(189deg 44% 31%) 0, transparent 50%),
      radial-gradient(at 80% 50%, hsl(340, 50%, 27%) 0, transparent 50%),
      radial-gradient(at 80% 100%, hsl(242, 48%, 22%) 0, transparent 50%);
    background-size: contain;
    content: "";
    transform: translate3d(0, 0, 0);
    will-change: transform;
  }

  html.valentine::after {
    background: hotpink;
    background-image: url("https://images.unsplash.com/photo-1484609047056-d27d44e97ede?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2072&q=80");
    background-size: contain;
  }

  @media screen and (prefers-reduced-motion) {
    html::after {
      animation: none;
    }
  }
}

/*
@keyframes blur {
  0%,
  90% {
    filter: blur(0) grayscale(1);
  }

  50% {
    filter: blur(50px);
  }
}

@keyframes travel {
  0%,
  100% {
    background-position-y: 0%;
  }

  50% {
    background-position-y: -100%;
  }
} */

code:not(.language-typescript):not(.language-htmlbars) {
  padding: 0.1rem 0.3rem 0.2rem;
  background: #9499a3;
  border-radius: 0.2rem;
  box-decoration-break: clone;
  color: black;
  font-family: monospace;
  word-wrap: break-word;
}

a {
  color: #61dafb;
}

small {
  font-size: 0.67rem;
}

img {
  max-width: 100%;
  max-height: 100%;
}

#emoji {
  position: absolute;

  /* display: none; */
}

#emoji::after {
  position: absolute;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "🦄";
  font-size: 3rem;
}

.pixel-font {
  font-family: "VT323", monospace;
  font-size: 0.7rem;
}

.print-hidden {
  @media print {
    display: none;
  }
}
